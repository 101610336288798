<template>
  <v-app>
    <v-app-bar app class="green lighten-5">
      <router-link to="/">
        <v-img
          alt="Federal Teaching Hospital, Ido"
          class="shrink mt-1 rounded-circle"
          contain
          min-width="50"
          src="uploads/logo.jpg"
          max-width="50"
        ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <div v-show="!mobile">
        <!-- <v-btn
          rounded
          text
          small
          outlined
          depressed
          color="green darken-4"
          class="mr-2"
          :href="mainHome"
        >
          <v-icon>mdi-home</v-icon>
          Main-Home
        </v-btn> -->
        <v-btn
          rounded
          text
          small
          outlined
          depressed
          color="green darken-4"
          class="mr-2"
          to="/"
        >
          <v-icon>mdi-bank</v-icon>
          Home
        </v-btn>

        <v-btn
          v-if="curUser"
          rounded
          text
          small
          outlined
          depressed
          color="green darken-4"
          class="mr-2"
          :to="{ name: 'patients' }"
        >
          <v-icon>mdi-account-multiple</v-icon>
          Patients
        </v-btn>
        <v-btn
          v-if="curUser"
          rounded
          text
          small
          outlined
          depressed
          color="green darken-4"
          class="mr-2"
          :to="{ name: 'newPatient' }"
        >
          <v-icon>mdi-plus-box</v-icon>
          New Patient
        </v-btn>
        <v-btn
          v-if="!curUser"
          rounded
          text
          small
          outlined
          depressed
          color="green darken-4"
          class="mr-2"
          :to="{ name: 'Login' }"
        >
          login
        </v-btn>
        <v-btn
          v-if="curUser"
          rounded
          text
          small
          outlined
          depressed
          color="green darken-4"
          @click="logout"
        >
          <v-icon>mdi-export</v-icon>
          Logout
        </v-btn>
      </div>

      <v-app-bar-nav-icon
        v-show="mobile"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :app="mobile"
      v-if="mobile"
      clipped
      right
      width="175"
    >
      <nav-drawer
        :hideDrawer="hideDrawer"
        :logout="logout"
        @click="logout"
      ></nav-drawer>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer padless class="green lighten-5">
      <div class=" mx-auto py-6 pl-2">
        <h5>
          Copyright &copy; {{ new Date().getFullYear() }} Neonatal Unit,
          Department of Paediatrics (FETHI). | Designed By:
          <a
            href="https://kinplusgroup.com"
            class="text-decoration-underline black--text"
          >
            Kinplus Technologies</a
          >
        </h5>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
// import { apiClient } from "@/services";
import { mapState, mapActions } from "vuex";
import NavDrawer from "./components/NavDrawer.vue";

export default {
  name: "App",

  components: {
    NavDrawer
  },

  data: () => ({
    drawer: false
  }),
  computed: {
    ...mapState(["mainHome", "curUser", "login"]),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  // created() {
  //   if (!this.curUser) {
  //     const curUserId = this.$route.query._c_u;
  //     if (curUserId && curUserId.length > 30) {
  //       apiClient
  //         .get("/user?id=" + curUserId)
  //         .then(res => {
  //           if (res.data && res.data.user_id) {
  //             this.setCurUser(res.data);
  //             this.$router.push({ name: "home" });
  //           }
  //         })
  //         .catch(err => {
  //           console.log(err.message);
  //         });
  //     } else {
  //       this.redirectToMainHome();
  //     }
  //   }
  // },
  watch: {
    mobile(val) {
      if (!val) {
        this.drawer = false;
      }
    }
  },
  methods: {
    ...mapActions(["setCurUser", "setLogin", "logout"]),

    hideDrawer() {
      if (this.mobile) {
        this.drawer = false;
      }
    },
    redirectToMainHome() {
      // location.assign(`${this.mainHome}${query ? "?" + query : ""}`);
    },
    logout() {
      this.loading = true;

      setTimeout(() => {
        this.$store.dispatch("logout");
        this.setLogin(false);
        this.loading = false;
      }, 500);
    }
  }
};
</script>
