import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    curUser: null,
    showAppbar: false,
    login: false,
    mainHome: 'https://fethipaediatrics.com/#/'
      // mainHome: "http://localhost/fthcbtPaed/#/"
  },
  mutations: {
    SET_SHOW_APPBAR(state, payload) {
      state.showAppbar = payload;
    },

    SET_CUR_USER: (state, payload) => {
      sessionStorage.setItem("curUser", JSON.stringify(payload));
      state.curUser = payload;
    },
    LOGOUT(state) {
      sessionStorage.removeItem("curUser");
      sessionStorage.clear();
      state.curUser = null;
      location.assign('/');
    },
    SET_LOGIN: (state, payload) => (state.login = payload)
  },
  actions: {
    setShowAppbar({ commit }, payload) {
      commit("SET_SHOW_APPBAR", payload);
    },
    setLogin: ({ commit }, payload) => commit("SET_LOGIN", payload),
    setCurUser: ({ commit }, payload) => commit("SET_CUR_USER", payload),
    logout({ commit }) {
      commit("LOGOUT");
    }
  },
  getters: {
    // userId:(state) => state.curUser.id || "",
    getCurUser: state =>
      state.curUser.last_name + " " + state.curUser.first_name,
    isAdmin: state => state.curUser.is_admin == 1
  },
  modules: {}
});