<template>
  <div class="pa-3 pb-5">
    <v-row>
      <v-col cols="12" md="6" class="pa-4">
        <v-card>
          <v-img src="bg.jpg" cover height="600"> </v-img>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="align-self-center">
        <h2 class="success--text px-4">
          Neonatal Unit, Department of Paediatrics (FETHI)
        </h2>
        <p class="pa-4">
          The Neonatal Unit of the Department of Paediatrics, Federal Teaching
          Hospital Ido-Ekiti (FETHI) admits newborns from the 1st day of life
          till 30 days. The Healthcare Professionals in the unit are committed
          to providing quality neonatal care to small and sick newborns
          including Extremely Low Gestational Age Newborns and Micropremies.
          Human Resources, Equipment and Technology are constantly improved to
          provide the best health care that we can to newborns.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.mt {
  margin-top: -150px;
}
@media screen and (max-width: 650px) {
  h1 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
  .mt {
    margin-top: -300px;
    width: 400px;
  }
}
</style>
