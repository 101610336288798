import Vue from "vue";
// import auth from "@/auth";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/neonatal-unit",
    alias: "/",
    name: "Home",
    component: Home
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      import ( /* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/admin-dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: () =>
      import ( /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  },

  {
    path: "/patients-application",
    name: "newPatient",
    meta: { requiresAuth: true },
    props: route => ({ editUser: route.params.editUser }),
    component: () =>
      import (
        /* webpackChunkName: "patientsApplication" */
        "../views/PatientsApplication.vue"
      )
  },

  {
    path: "/patients",
    name: "patients",
    meta: { requiresAuth: true },
    component: () =>
      import (
        /* webpackChunkName: "patientslist" */
        "../components/PatientsDetails.vue"
      )
  },

  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () =>
      import ( /* webpackChunkName: "NotFound" */ "@/views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  const loggedIn = sessionStorage.getItem("curUser");
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next({ name: "Login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;