import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import excel from "vue-excel-export";
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(excel);
Vue.use(VueExcelXlsx);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  created() {
    const userString = sessionStorage.getItem("curUser");
    if (userString) {
      const payload = JSON.parse(userString);
      this.$store.commit("SET_CUR_USER", payload);
    }
  },
  render: h => h(App)
}).$mount("#app");