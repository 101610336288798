<template> 
  <div class="d-block">
    <v-list>
    <!-- <v-list-item
        ><v-btn color="green darken-4" @click="hideDrawer" :href="mainHome" text
          ><v-icon>mdi-home</v-icon>Main-Home</v-btn
        ></v-list-item
      > -->
      <v-list-item
        ><v-btn
          color="green darken-4"
          @click="hideDrawer"
          :to="{ name: 'Home' }"
          text
          ><v-icon>mdi-bank</v-icon> Home</v-btn
        ></v-list-item
      >
      <v-list-item
        v-if="curUser"
        ><v-btn        
          color="green darken-4"
          @click="hideDrawer"
          :to="{ name: 'patients' }"
          text
          ><v-icon>mdi-account-multiple</v-icon> Patients</v-btn
        ></v-list-item
      >
      <v-list-item
        v-if="curUser"
        ><v-btn
          color="green darken-4"
          @click="hideDrawer"
          :to="{ name: 'newPatient' }"
          text
          ><v-icon>mdi-plus-box</v-icon> New Patient</v-btn
        ></v-list-item
      >
      <v-divider></v-divider>
      <v-list-item
        v-if="curUser"
        ><v-btn color="green darken-4" @click="logout" text
          ><v-icon>mdi-export</v-icon> Logout</v-btn
        ></v-list-item
      >
      <v-list-item
        v-else
        ><v-btn 
        color="green darken-4" 
        :to="{ name: 'Login' }" 
        text
          ><v-icon>mdi-import</v-icon> Login</v-btn
        ></v-list-item
      >
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NavDrawer",

  // drawer: false,

  props: {
    hideDrawer: Function,
    logout: Function
  },

  data: () => ({
    //
  }),
  computed: mapState(["mainHome", "curUser"])
};
</script>
